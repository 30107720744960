.contentRightContainer{
    font-size: 0.7em;
    padding: 1em;
    width: 22em;
}
.contentRightContainer img{
    border-radius: 25px;
    width: 16em;
    height: 16em;
}

@media screen and (max-width: 768px) {
    .contentRightContainer img{
    width: 100%;
    height: 100%;
    border-radius: 25px;


    }
    .contentRightContainer{
        padding: 0em;
        width: 50em;
    }
  }