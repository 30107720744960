.contentLeftContainer {
    font-size: 0.9em;
    padding: 0.5em;
}

.contentHeading {
    font-weight: bolder;
    margin-bottom: 1em;
    min-height: 3em;
    min-width: 10em;
    display: flex;
    flex-direction: column;
    
}

.buttonGetaQuote {
    border: 2px solid transparent;
    border-radius: 2em;
   
    color: #000;
    width: 10em;
    height: 3em;
    font-weight: bold;
    padding: 0.3em;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    background-color: #FFEE54;
}

.buttonGetaQuote:hover {
    box-shadow: 0px 14px 10px -6px rgb(255 238 84 / 40%);
    transition: all 0.2s ease-in-out;
    background: #FFEE54;
}

@media screen and (max-width: 768px) {
    .buttonGetaQuote {
        font-size: 1em;
        padding: 0.1em;
        width: 10em;
        height: 3em;
    }
    .contentHeading {
        font-size: 2.5em;
        min-height: 4em;
        padding-top: 2em;
    }
}

@media screen and (max-width: 448px) {
    .contentHeading {
        font-size: 2.3em;
        min-height: 5.2em;
        padding-top: 3em;
    }
}
@media screen and (max-width: 1280px) {
    .contentHeading {
        
        padding-top: 1em;
    }
}

