.contentBlockContainer{
  display: flex;
  justify-content: center;
  margin: 4% 2em 0 2em;
  align-items: center;
  flex-direction: row;
  color: #fff;
  font-size: 3rem;
}

/* @media screen and (max-width: 1100px) {
.contentBlockContainer{
/* margin-top: 2.5em;    
}
}*/

@media screen and (max-width: 609px) {
  .contentBlockContainer{
    /* padding-top: 4.1%; */
  margin-top: 10%;    
  font-size: 0.9rem;
  flex-direction: column;
  }
}

@media screen and (max-width: 770px) {
  .contentBlockContainer{
    /* padding-top: 4.1%; */
  margin-top: 5%;    
  font-size: 0.9rem;
  flex-direction: column;
  }
}
@media screen and (max-width: 408px) {
  .contentBlockContainer{
    /* padding-top: 3.7%; */
  margin-top: 15%;    
  font-size: 0.7rem;
  flex-direction: column;
  }
} 