@import url(https://fonts.googleapis.com/css?family=Open+Sans:600,300);

$darkBlue: #002E6C;

.center {
 
	height: 299px;
	width: 320px;
	background: #fff;
	border-radius: 15px;
	overflow: hidden;
	box-shadow: 10px 10px 15px 0 rgba(0,0,0,0.3);
}

.profile {
	float: left;
	width: 200px;
	height: 320px;
	text-align: center;
	
	.image {
		position: relative;
		width: 70px;
		height: 70px;
		margin: 38px auto 0 auto;
		
		.circle-1 {
			position: absolute;
			box-sizing: border-box;
			width: 76px;
			height: 76px;
			top: -3px;
			left: -3px;
			border-width: 1px;
			border-style: solid;
			border-color: $darkBlue $darkBlue $darkBlue transparent;
			border-radius: 50%;
			transition: all 1.5s ease-in-out;
		}
		
		.circle-2 {
			@extend .circle-1;
			width: 82px;
			height: 82px;
			top: -6px;
			left: -6px;
			border-color: $darkBlue transparent $darkBlue $darkBlue;
		}
		
		img {
			display: block;
			border-radius: 50%;
			background: #499AD4;
		}
		
		&:hover {
			cursor: pointer;
			
			.circle-1 {
				transform: rotate(360deg);
			}
			
			.circle-2 {
				transform: rotate(-360deg);
			}
			
		}
		
	}
	
	.name {
		font-size: 15px;
		font-weight: 600;
		margin-top: 20px;
	}
	
	.job {
		font-size: 11px;
		line-height: 15px;
	}
	
	.actions {
		margin-top: 33px;
		
		.btn {
			display: block;
			width: 120px;
			height: 30px;
			margin: 0 auto 10px auto;
			background: none;
			border: 1px solid $darkBlue;
			border-radius: 15px;
			font-size: 12px;
			font-weight: 600;
			box-sizing: border-box;
			transition: all .3s ease-in-out;
			color: $darkBlue;
			cursor: pointer;
			&:hover {
				background: $darkBlue;
				color: #fff;
			}
		}
	}
	
}

.stats {
	float: left;
	
	.box {
		box-sizing: border-box;
		width: 120px;
		height: 99px;
		background: #499AD4;
		text-align: center;
		padding-top: 28px;
		transition: all .4s ease-in-out;
		
		&:hover {
			background: $darkBlue;
			cursor: pointer;
			color: #fff;

		}
		
		&:nth-child(2) {
			margin: 1px 0;
		}
		
	}
	
	span {
		display: block;
	}
	
	.value {
		font-size: 18px;
		font-weight: 600;
	}
	
	.parameter {
		font-size: 11px;
	}
	
}
