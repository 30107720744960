.commonCard {
    background-color: #f0f6ff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    padding: 20px;
}

.commonButton {

    display: block;
    padding: 0.5em 1em;
    text-transform: uppercase;
    margin: 0 auto 10px auto;
    background: none;
    border: 1px solid #002E6C;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    box-sizing: border-box;
    transition: all .3s ease-in-out;
    color: #002E6C;
    cursor: pointer;


}

.successButton{
    display: block;
    padding: 0.5em 1em;
    text-transform: uppercase;
    margin: 0 auto 10px auto;
    background: none;
    border: 1px solid #002E6C;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    box-sizing: border-box;
    transition: all .3s ease-in-out;
    background-color: #439D44;
    color: #fff;
    cursor: pointer;
}

.commonButton:hover {
    background: #002E6C;
    color: #fff;
}

.commonButton:disabled,
button[disabled]{
  border: 1px solid #002E6C;
  background-color: #cccccc;
  color: #666666;
}