a {
    color: #000;
}


/* header */

.header {
    background-color: #fff;
    box-shadow: 0 1px 9px 2px #33333369;
    position: fixed;
    width: 100%;
    height: 60px;
    z-index: 17000;
    /* box-shadow:0px 14px 10px -6px rgb(255 238 84 / 50%) #000; */
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
    
}
.tagLine{
    visibility: hidden;
    width: 0;
    height: 0;
    font-size: 40px;
}
.header li a {
    display: block;
    padding: 20px 20px;
    border-right: 1px solid #f4f4f4;
    text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
    background-color: #f4f4f4;
}

.selected {
    color: #217EDC;
}

.header .logo {
    display: flex;
    align-items: center;
    float: left;
    font-size: 2em;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    text-decoration: none;
    margin-left: 0;
}


/* menu */

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
    margin-right: 140px;
}

@media (max-width: 69em) {
    .header .menu {
        text-align: center;
        width: 100%;
    }
}


/* menu icon */

.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 16px 10px;
    position: relative;
    user-select: none;
}

.header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}


/* menu btn */

.header .menu-btn {
    display: none;
}

.navOpen {
    max-height: 370px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 8px 20px 2px #33333352;

    border-radius: 0 0 20px 20px;
}

.header .menu-btn:checked~.menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked~.menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.header .menu-btn:checked~.menu-icon .navicon:after {
    transform: rotate(45deg);
}

.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
    top: 0;
}


/* 48em = 768px */

@media (min-width: 1123px) {
    .closeIcon {
        visibility: hidden;
    }
    .tagLine{
        visibility: visible;
        width: fit-content;
        height: fit-content;
        font-size: small;
        margin-left: 2em;
        font-weight: 600;
        text-align: center;
    }
    .header {
        background-color: #fff;
        position: fixed;
        width: 100%;
        display: block;
        align-items: center;
        justify-content: space-between;
        z-index: 17000;
    }
    .header li {
        float: left;
    }
    .header li a {
        padding: 15px 30px;
    }
   
    .header .menu {
        clear: none;
        float: right;
        max-height: none;
        display: flex;
        align-items: center;
        margin-right: 0;

    }
    .header .menu-icon {
        display: none;
    }
    .header .logo {
        display: flex;
        align-items: center;
        float: left;
        font-size: 2em;
        text-decoration: none;
        margin-left: 80px;
    }
    .header .logo img {
        width: 4.5em;
    }
}