.custom-shape-divider-bottom-1619165531 {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1619165531 svg {
    position: relative;
    display: block;
    width: calc(237% + 1.3px);
    height: 212px; 
}

.custom-shape-divider-bottom-1619165531 .shape-fill {
    fill: #0156BD;
}

/** For mobile devices **/
@media (max-width: 767px) {
    .custom-shape-divider-bottom-1619165531 svg {
        width: calc(245% + 1.3px);
        height: 164px;
    }
}

.landingPageContainer::-webkit-scrollbar {
    display: '1em';
}
.landingPageContainer{
    scrollbar-width: 1em;
}
.custom-shape-divider-bottom-1619168723 {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    
}

.custom-shape-divider-bottom-1619168723 svg {
    position: relative;
    display: block;
    width: calc(166% + 1.3px);
    height: 61px;
}

.custom-shape-divider-bottom-1619168723 .shape-fill {
    fill: hsl(0, 0%, 100%);
}

.card-container{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    color:#fff;
    align-items: flex-start;
    background: #0156BD; 
    padding-bottom: 9.7em;
}
.reviewContainer{
    display:flex;
    justify-content: center;
    margin-top:1em;
    cursor: pointer;
}

.masterPiece{
    margin: 5em 0em;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
}
.masterPiece .heading{
    font-weight:bolder;
    font-size: 1.5em;
    padding: 0.5em;
}
.masterPiece .desc{
    font-weight:200;
    font-size: 1em;
    width: 30em;
}
@media screen and (max-height: 700px) {
    .masterPiece{
        margin: 5em 0em 5em 0em;
      }
    
}
@media screen and (max-width: 480px) {
    .card-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px ;
        
        font-size: 0.8em;
    }
    .masterPiece{
        margin-top: 5em 0em 2em 0em;
        font-size: 0.7rem;
    }
    .masterPiece .heading{
        font-weight:bolder;
        font-size: 1.5em;
        padding: 0.5em;
    }
    .masterPiece .desc{
        font-weight:200;
        font-size: 1em;
        width: 30em;
    }
}



.MasterPieceCardsContainer{
    margin-top: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
}











